import React, {Component} from "react";

import Themes from "./demo_data/themes";
import Books from "./demo_data/books";
import "./css/choose_book.css";
import {
    Grid,
    Checkbox,
    FormControl,
    Paper,
    Chip,
    FormGroup,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Collapse,
    Container, Box,
    Button
} from "@material-ui/core";
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import {makeStyles} from "@material-ui/core";
import {withStyles} from "@material-ui/core";
import http from "../api-service/http-common";

const THEMES_URL = 'https://personalshopper.personalisedstories.co.uk/api/themes/';
const BOOKS_URL = 'https://personalshopper.personalisedstories.co.uk/api/books/';
const UPLOAD_URL = "https://personalisedstories.co.uk/wp-content/uploads/";
const PRODUCT_URL = 'https://personalisedstories.co.uk/product/';

const useStyles = ((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

class ChooseYourBook extends Component {
    constructor(props) {
        super(props);
        let {theme, books} = this.props.form_data;
        if(!books){
            books = []
        }
        this.state = {
            themes: [],
            books: [],
            theme,
            books_selected: books,
        };
    }

    componentDidMount() {
        this.resetState();

    }
    form_validator = (checked,book) => {
        this.handle_book_change(checked, book);
        if(this.props.form_data.books.length===this.props.form_data.books_included){
            this.props.handle_state_change({
                next_button_disabled: false,
            })
        }else{

            this.props.handle_state_change({
                next_button_disabled: true,
            })
        }
    };
    getThemes = ()=>{
        http.get(THEMES_URL).then(
                res => {
                    this.setState({themes: res.data.map((theme) => {
                        return {
                            id: theme.term__term_id,
                            name: theme.term__name,
                        }
                        }), theme: res.data[0].id});



                    console.log(this.state);
                }

        )
    };
    getBooks = (theme_id)=>{
        if(theme_id===this.state.theme){
            this.setState({theme: null });
            return;
        }
        this.setState({theme: theme_id, books: []});

        http.get(BOOKS_URL, {
            params:{
                theme_id: theme_id,
            }
        }).then(
            res => {
                let books = {
                    books: res.data.map((book) => {
                        return {
                            id: book.pk,
                            name: book.post_title,
                            image: book.image,
                            slug: book.slug,
                        }}),
                    };
                this.setState(books);
                const change = this.props.handle_change;
                change({
                        target: {
                            name: 'all_books',
                            value: books.books,
                        }
                    }
                );
                this.setState({});
            }

        )

    };
    resetState = ()=>{
      this.getThemes();
    };
    handle_book_change = (checked, book)=>{

        var value = book.id;

        var new_list;
        if(checked){
             new_list= this.state.books_selected.concat(book);

            this.setState({
                books_selected: new_list
            })
        }
        else{
            new_list = this.state.books_selected.filter((book_) =>{
                return book_ !== book;
            });

            this.setState({
                books_selected: new_list
            })
        }
        this.props.handle_change({
            target: {
                name: 'theme',
                value: this.state.theme,
            }
            }
        );
        this.props.handle_change({
            target: {
                name: 'books',
                value: new_list,
            }
        });

};
    render() {

        // console.log(JSON.stringify(books));
        // console.log(JSON.stringify(themes));
        const {classes} = this.props;
        return (
            <Grid item xs={12} maxWidth={'lg'} class="container" style={{fontFamily: 'Poppins'}}>

                    <Grid container>
                        {this.state.books_selected.length} out of {this.props.form_data.books_included} Books Selected
                    </Grid>
                <List>
                    {
                        !this.state.themes || this.state.themes.length<=0? (''): (this.state.themes.map((theme,index) => (
                            <>
                            <ListItem button onClick={() => this.getBooks(theme.id)}>

                                <ListItemText >{theme.name} </ListItemText>
                                {this.state.theme === theme.id ? <ExpandLess/> : <ExpandMore />}
                            </ListItem>

                                <Collapse in={this.state.theme === theme.id} timeout="auto" unmountOnExit>
                                    <Grid container spacing={5} alignItems="flex-start">
                                        {

                                            !this.state.books || this.state.books.length <= 0 ? ('') : this.state.books.map(book => (


                                                <Grid item xs={6} sm={4} md={2}>
                                                    <Grid container>
                                                        <label>
                                                            <Grid container>
                                                                <img id={'book_img'} src={UPLOAD_URL + book.image}
                                                                     style={{width: '100%',}}/>
                                                            </Grid>

                                                            <Grid container>
                                                                <Checkbox
                                                                    name="books"
                                                                    value={book.id}
                                                            checked={this.state.books_selected.find(b=>b.id===book.id)}
                                                            onClick={(event) => this.form_validator(event.target.checked, book)}
                                                            disabled={(this.state.books_selected.length >= this.props.form_data.books_included) && (!this.state.books_selected.includes(book)) }
                                                        />{book.name}
                                                    </Grid>
                                                    </label>
                                                    <Grid container>
                                                        <Grid item>
                                                            <Button component={'a'} target='_blank'
                                                                    variant={'contained'}
                                                                    href={PRODUCT_URL + book.slug}>View Now</Button>
                                                        </Grid>
                                                    </Grid>
                                                    </Grid>

                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                    {this.state.themes.length===index+1 ? '':
                                    <Grid container >
                                        <Paper component="ul">
                                            {this.state.books_selected.map((book) => {
                                                let icon;
                                                return (
                                                    <li key={book.id}>
                                                        <Chip
                                                            icon={icon}
                                                            label={book.name}
                                                            onDelete={() => this.form_validator(false, book)}
                                                        />
                                                    </li>
                                                );
                                            })}
                                        </Paper>
                                    </Grid>
                                    }

                                </Collapse>


                            </>

                        )))
                    }

            </List>
                <Grid container >
                    <Paper component="ul">
                        {this.state.books_selected.map((book) => {
                            let icon;
                            return (
                                <li key={book.id}>
                                    <Chip
                                        icon={icon}
                                        label={book.name}
                                        onDelete={() => this.form_validator(false, book)}
                                    />
                                </li>
                            );
                        })}
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(useStyles, {withTheme: true}) (ChooseYourBook);


import React, {Component} from "react";
// eslint-disable-next-line no-unused-vars
import {
    Container,
    Tab,
    Tabs,
    Card,
    Button,
    CardTitle,
    CardText,
    Grid,
    AppBar,
    Box, MobileStepper
} from "@material-ui/core";

import './css/choos_your_plan.css';
import PlansInfo from "./PlanInfo";
import Plans from "./demo_data/plans";
import ChooseYourBook from "./choose-books";
import ChooseYourBookExtras from "./choose-your-book-extras";
import NameCharacter from "./name-character";
import ChooseCharacter from "./choose-character";
import ReadyToCheckout from "./ready_to_checkout";
import {base_site, site_urls} from "./constants";
import { withRouter } from "react-router-dom";

import {makeStyles} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {withStyles} from "@material-ui/core";
import * as PropTypes from "prop-types";
import './css/fonts/poppins.css';
import Notify from "../utils/Notify";
import {connect} from "react-redux";
import StepOne from './ChoosePlan';
import {groupBy} from './constants';
import http from "../api-service/http-common";
import ShippingAddressForm from "./shipping_address";


const constants_url = '/api/constants/';

const styles = ((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    actionsContainer: {
        marginTop: theme.spacing(1),
    }
}));


function getSteps() {
    return [

        'Choose Plan Type',
        'Choose Book',
        'Choose Book Extras',
        'Name Character',
        'Choose Character',
        'Shipping Address',
        'Checkout',
    ];
}
class ChooseYourPlan extends Component{

    constructor(props) {

        super(props);
        this.form_data = {
            'book-type': 'paperback',
            payment_type: null,
            book_props: {},
            character_options: {},
            books: [],
            address: {},
            child: {},
            preferred_days: [1, 15],
        };

        this.books = [];
        this.state = {
            currentStep: 0,
            active_tab: 0,
            next_button_disabled: true,
            loading: false,
            show_modal: false,
            modal_body: '',
            is_product_modal: false,

        };
        this.one_time_url = base_site + '/api/start-subscription/';
        this.pay_as_go_url = base_site + '/api/start-monthly-subscription/';
        // console.log(this.start_url);

    }

    componentDidMount() {
        // TODO: add a get method to get last formdata of customer.

        http.get(constants_url).then(res => {
            this.form_data.postage_per_book = parseFloat(res.data.filter(cons => cons.meta_key === 'postage_price_book')[0].meta_value);
        })
    }

    modal_close_click = event => {
        if (this.state.is_product_modal)
            this.props.history.push(site_urls.subscription_list);
        this.setState({show_modal: false, is_product_modal: false})

    }
    handle_form_change = event => {
        const {name, value} = event.target;

        this.form_data[name] = value;
        console.log(this.form_data);

    };
    handle_state_change = target => {
      this.setState(target);
    };
    next = () => {
            this.setState({currentStep: this.state.currentStep + 1, next_button_disabled: true})
    };
    previous = () => {
        this.setState({currentStep: this.state.currentStep-1})
    };
    finish_payment = (event) => {
        Notify.NotifyLoading();
        const {plan_name} = this.form_data;
        let end_url;
        if (plan_name === "Pay As You Go") {
            end_url = this.pay_as_go_url;
        } else {
            end_url = this.one_time_url;
        }

        this.start_subscription(end_url).then(res => {
            Notify.notifySuccess('Subscribed Successfully', site_urls.subscription_list.url);
        }).catch(err=>{
            Notify.notifyError(err.response.data.error);
        });
}
    start_subscription = (end_url) => {
        this.setState({loading: true});
        return http.post(end_url ,this.form_data).then((res) =>{
            this.setState({loading: false, show_modal:true, modal_body: JSON.stringify(res.data), is_product_modal:true});
        });
    }
    steps = () => {
        switch
            (this.state.currentStep+1) {
            case 1:
                return (
                           <StepOne handle_state_change={this.handle_state_change} handle_change={this.handle_form_change} form_data={this.form_data}/>
                );
            case 2:
                return (
                    <ChooseYourBook handle_state_change={this.handle_state_change} handle_change={this.handle_form_change} form_data={this.form_data}/>
                );
            case 3:
                return (
                    <ChooseYourBookExtras handle_state_change={this.handle_state_change} handle_change={this.handle_form_change} book_ids={this.state.books} form_data={this.form_data}/>
                );
            case 4:
                return (
                    <NameCharacter handle_state_change={this.handle_state_change}
                                   handle_change={this.handle_form_change} book_ids={this.state.books}
                                   form_data={this.form_data}/>
                );
            case 5:
                return (
                    <ChooseCharacter handle_state_change={this.handle_state_change}
                                     handle_change={this.handle_form_change} book_ids={this.state.books}
                                     form_data={this.form_data}/>
                );
            case 6:
                return <ShippingAddressForm handle_state_change={this.handle_state_change}
                                            handle_change={this.handle_form_change} book_ids={this.state.books}
                                            form_data={this.form_data}/>
            case 7:
                return (
                    <ReadyToCheckout handle_state_change={this.handle_state_change}
                                     handle_change={this.handle_form_change} book_ids={this.state.books}
                                     form_data={this.form_data}/>
                );
            default:

                return (
                    <StepOne handle_state_change={this.handle_state_change} handle_change={this.handle_form_change}/>

                );

        }
    };
    render() {
        var steps = getSteps();
        // const classes = useStyles();
        const {classes} = this.props;
        if (!this.props.auth.isAuthenticated){
            Notify.notifyInfo('You are not logged in, register or login to continue', '', false, 'info');
        }
        return(
            <>
                <div id={'main'} className={classes.root}>
                        <Box  style={{margin: '1em', marginBottom: "4em"}}>{this.steps()}</Box>
                        <Grid container xs={12} align="center" justify = "center" alignItems = "center" className={classes.actionsContainer}>
                                <Grid item xs={8}>
                                <MobileStepper
                                    variant="dots"
                                    steps={6}
                                    position="bottom"
                                    activeStep={this.state.currentStep}
                                    backButton={<Button
                                        disabled={this.state.currentStep === 0}
                                        onClick={this.previous}
                                        className={classes.button}
                                    >
                                        Back
                                    </Button>}
                                    nextButton={<Grid item>

                                        {this.state.currentStep === steps.length - 1 ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.finish_payment}
                                                className={classes.button}
                                                disabled={this.state.next_button_disabled}
                                            >
                                                Finish
                                            </Button>

                                            :
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.next}
                                                className={classes.button}
                                                disabled={!(!this.state.next_button_disabled && this.props.auth.isAuthenticated)}
                                            >
                                                Next
                                            </Button>

                                        }

                                    </Grid>}
                                />
                                </Grid>
                        </Grid>

                    {this.currentStep === steps.length && (
                        <Paper square elevation={0} className={''}>
                            <Typography>All steps completed - you&apos;re finished</Typography>
                            <Button  className={classes.button}>
                                Reset
                            </Button>
                        </Paper>
                    )}
                </div>
            </>
        )

    }

    nextButton() {
        console.log(this.state.currentStep);
        return (
            this.state.currentStep !== 7 ? (
                <Button onClick={this.next} style={{marginLeft: '1em'}}
                        disabled={this.state.next_button_disabled}>Next</Button>
            ) : (
                (this.state.loading ? (<spinner animation="border"/>) : (
                    <Button onClick={this.start_subscription} type={'submit'} style={{marginLeft: '1em'}}
                            disabled={this.state.next_button_disabled}>Add to basket</Button>
                ))
            )
        )
    }

    previousButton() {
        return (
            <Button onClick={this.previous}>Previous</Button>
        )
    }
}


const mapStatetoProps = state =>({
    auth: state.auth
})

export default withStyles(styles, {withTheme: true}) (connect(mapStatetoProps) (withRouter(ChooseYourPlan)));

import {Component} from "react";
import {Container, } from "@material-ui/core";
import {FormControl, FormLabel, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import './css/choose_character.css'
const base_url = "https://personalshopper.personalisedstories.co.uk/static_files/subscriptions/";

class ChooseCharacter extends Component{
    constructor(props) {
        super(undefined);
        this.eyes = ["green", "brown", "blue"];
        this.data = {
            "Boy": {
                "body": [
                    "boy_skin_1.png",
                    "boy_skin_2.png",
                    "boy_skin_3.png",
                    "boy_skin_4.png"
                ],
                "clothes": [
                    "boy_clothes_1.png",
                    "boy_clothes_2.png"
                ],
                "eyes": [
                    "boy_blue.png",
                    "boy_brown.png",
                    "boy_green.png"
                ],
                "hair": [
                    "boy_Hair_1_-_Black.png",
                    "boy_Hair_1_-_Blonde.png",
                    "boy_Hair_1_-_Brown.png",
                    "boy_Hair_2_-_Black.png",
                    "boy_Hair_2_-_Blonde.png",
                    "boy_Hair_2_-_Brown.png",
                    "boy_Hair_3_-_Black.png",
                    "boy_Hair_3_-_Blonde.png",
                    "boy_Hair_3_-_Brown.png"
                ],
                "headpiece": [
                    "boy_headpiece_0.png",
                    "boy_headpiece_1.png",
                    "boy_headpiece_2.png"
                ]
            },
            "Girl": {
                "body": [
                    "girl_skin_1.png",
                    "girl_skin_2.png",
                    "girl_skin_3.png",
                    "girl_skin_4.png"
                ],
                "clothes": [
                    "girl_clothes_1.png",
                    "girl_clothes_2.png"
                ],
                "eyes": [
                    "girl_blue.png",
                    "girl_brown.png",
                    "girl_green.png"
                ],
                "hair": [
                    "girl_Hair_1_-_Black.png",
                    "girl_Hair_1_-_Blonde.png",
                    "girl_Hair_1_-_Brown.png",
                    "girl_Hair_2_-_Black.png",
                    "girl_Hair_2_-_Blonde.png",
                    "girl_Hair_2_-_Brown.png",
                    "girl_Hair_3_-_Black.png",
                    "girl_Hair_3_-_Blonde.png",
                    "girl_Hair_3_-_Brown.png"
                ],
                "headpiece": [
                    "girl_headpiece_0.png",
                    "girl_headpiece_1.png",
                    "girl_headpiece_2.png"
                ]
            }
        }
        this.gender = props.form_data.child.gender;
        const {character_options} = props.form_data;
        console.log(character_options)
        const {body, eyes, hair} = character_options
        this.state = {
            ...character_options
        }


    }

    componentDidMount() {
        this.props.handle_state_change({next_button_disabled: false})
        const state = this.state;
        // eslint-disable-next-line array-callback-return
        Object.entries(this.data[this.gender]).map(entry=>{
            const [key,values] = entry;
            if(!state[key])
            state[key] = values[0];
        })
        console.log(state);
        this.setState(state);
    }
    handleChange = event => {
        const {name,value} = event.target
        const s = this.state;
        s[name] = value;
        this.setState(s);
        this.props.handle_change({
            target:{
                name: 'character_options',
                value: s,
            }
        });

    }
    render() {
        //const {value, } = this.state;
        const gender = this.gender

        return (
            <Container>
                <form>
                <Container className={'tail'} id='images' >
                    {Object.entries(this.state).map((entry)=> {
                        const [name, value] = entry;

                        return (
                            <img src={base_url+gender+'/'+name+'/'+value} alt={name + ' ' + value}/>
                        )
                    })}

                </Container>
                    {Object.entries(this.data[gender]).map((types) =>{
                        const [name,values] = types;
                        return (<Container id={name}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{name}</FormLabel>
                                    <RadioGroup id={'cascade'}  aria-label="gender" name={name} value={this.state[name]} onChange={this.handleChange} defaultValue={this.data[gender][name][0]}>
                                        { values.map(value => (
                                            <>
                                                <FormControlLabel value={value} control={<Radio />} label={value} />

                                            </>
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </Container>
                        )
                    })}
                </form>
            </Container>
        )
    }
}

export default ChooseCharacter

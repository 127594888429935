import React from "react";
import {Grid} from "@material-ui/core";
import http from "../api-service/http-common";
import {total_price} from "./constants";


export default class TotalPrice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            postage_price: 0,
        };
    }

    componentDidMount() {

    }

    render() {
        const {form_data} = this.props;
        const {additional_price, total, discounted_price, postage_price} = total_price(form_data);

        return (
            <div>
                <Grid container justify={"space-evenly"}>
                    <Grid item xs={3}>Subtotal</Grid>
                    <Grid item xs={3}>£ {this.props.form_data.price}</Grid>
                </Grid>
                <Grid container justify={"space-evenly"}>
                    <Grid item xs={3}>Extras</Grid>
                    <Grid item xs={3}>£ {additional_price.toFixed(2)}</Grid>
                </Grid>
                <Grid container justify={"space-evenly"}>
                    <Grid item xs={3}>Shipping</Grid>
                    <Grid item xs={3}>£ {(postage_price).toFixed(2)}</Grid>
                </Grid>

                <Grid container justify={"space-evenly"}>
                    <Grid item xs={3}>Total</Grid>
                    <Grid item xs={3}>£ {total.toFixed(2)}</Grid>
                </Grid>
                {
                    form_data.coupon_code ?
                        <Grid container>
                            <p><h2>After Applying Coupon</h2></p>
                            <Grid container justify={"space-evenly"}>
                                <Grid item xs={3}>Discounted Price</Grid>
                                <Grid item xs={3}>£ {(discounted_price).toFixed(2)}</Grid>
                            </Grid></Grid>
                        : ''}
            </div>
        );
    }
}

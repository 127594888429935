import React, {Component} from "react";
import qs from "qs";
import {
    Container,
    Input,
    FormLabel,
    Select,
    Switch,
    Grid,
    Checkbox,
    Collapse,
    List,
    ListItem,
    ListItemText, Paper, Chip, Button, withStyles
} from "@material-ui/core";
import ImageGiftBox from "./book-extras";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import http from "../api-service/http-common";
import TotalPrice from "./total-price";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {makeStyles} from "@material-ui/core/styles";

let BOOKS_URL = '/books/';


const useStyles = ((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
}));


class ChooseYourBookExtras extends Component{

    constructor(props) {
        super(props);
        const {books, book_props} = this.props.form_data;

        this.state = {
            books,
            book_props,
            expanded: '',
        };
        this.form_data = {};

    }
    componentDidMount() {
        this.props.handle_state_change({next_button_disabled: false});
    }
    handle_form_change = event => {
        const {name, value} = event.target;
        // eslint-disable-next-line no-unused-vars
        console.log(event);
        const {book_props} = this.state;
        book_props[name] = value;
        console.log(book_props);
        this.setState({book_props})
        // this.setState({
        //     additional_price: this.state.additional_price + price
        // });
        this.props.handle_change({
            target: {
                name: 'book_props',
                value: book_props,
            }
        })
        // this.props.form_data['book_props'] = book_props;
        // console.log(this.props.form_data);
    };

    handleAccordionChange = (panel) => (event, isExpanded) => {
        this.setState({expanded: isExpanded ? panel : false});
    };

    render() {
        const {classes} = this.props;
        const {expanded} = this.state;
        // console.log(this.state);
        return (
            <Container>
                <Grid container>

                </Grid>
                <Grid container>
                    <h3>The First Book Includes A Free Personalised Messages & Image!</h3>
                </Grid>
                <Grid container>
                    <h3> Add A personalised Messages & image for just £ 2 per Additional Book.</h3>
                </Grid>
                <Grid container>
                    <h3>Add a gift box option for £5 per book.</h3>
                </Grid>
                <Grid container style={{marginBottom: 2}}>
                    <Container>
                        {this.state.books.map(book => (
                            <Accordion expanded={expanded === book.name}
                                       onChange={this.handleAccordionChange(book.name)}
                                       style={{backgroundColor: "#f8e7a4"}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Typography className={classes.heading}>{book.name}</Typography>

                                </AccordionSummary>
                                <AccordionDetails id={'auction_details'}>
                                    <ImageGiftBox book={book} update_form={this.handle_form_change}
                                                  form_data={this.props.form_data}/>
                                </AccordionDetails>
                            </Accordion>
                        ))}

                    </Container>


                </Grid>
                <TotalPrice form_data={this.props.form_data}/>
            </Container>
        );
    }

    getBooks = (book_ids)=>{
        http.get(BOOKS_URL, {
            params: {
                books: book_ids
            },
            paramsSerializer: params => {
                    return qs.stringify(params)
                }},
            ).then(
            res => {
                this.setState({books: res.data});
                // console.log(this.state);
                // $('#choose-books')[0].reset();
            }
        )

    };

}

export default withStyles(useStyles, {withTheme: true})(ChooseYourBookExtras);


import React, {Component} from "react";
import {
    Container,
    Grid,
    Checkbox,
    Radio,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    TextField, Button,
} from "@material-ui/core";
import {base_site, total_price} from "./constants";
import CustomizedDialogs from "./modal-template";
import CreatePaymentMethod from './create-payment-method';
import http from "../api-service/http-common";
import TotalPrice from "./total-price";
import Notify from "../utils/Notify";

const coupon_check = '/api/coupon/is_valid/';

// window.http = http;

class ShowData extends Component {
    render() {
        return (
            <div>

            </div>
        );
    }
}

class ReadyToCheckout extends Component{
    constructor(props) {
        super(undefined);
        const {coupon_code, payment_id} = props.form_data;
        this.state = {coupon_code, payment_id, methods: []};

    }

    componentDidMount() {
        this.update_methods();
        this.validator();
    }

    update_methods = () => {
        http.get(base_site + '/api/payment-methods/')
            .then((res) => {
                this.setState({methods: res.data.data});
            })
    }

    handleChange = event => {
        this.props.handle_change(event);
        this.setState({payment_id: event.target.value})
        this.props.handle_state_change({
            next_button_disabled: false,
        })

    };
    validator = () => {
        const {additional_price, total, discounted_price, postage_price} = total_price(this.props.form_data);
        console.log(this.state);
        console.log({additional_price, total, discounted_price, postage_price});
        if (discounted_price === 0 || this.state.payment_id) {

            this.props.handle_state_change({
                next_button_disabled: false,
            })
        } else {
            this.props.handle_state_change({
                next_button_disabled: true,
            })
        }
    }

    setState(state, callback) {
        super.setState(state, callback);
    }

    add_method = payment_data => {

        console.log(payment_data)
        this.update_methods();
    }

    check_coupon = event => {
        Notify.NotifyLoading();
        const code = this.state.coupon_code;
        console.log(this.state);
        http.get(coupon_check, {
            params: {
                coupon_code: code
            }
        }).then(res => {
            this.props.handle_change({target: {name: 'coupon_code', value: code}});
            this.props.handle_change({target: {name: 'coupon_data', value: res.data.reason}});
            Notify.notifySuccess('Coupon Applied Successfully', '', false,)
            this.validator();

            this.setState({});
        }).catch(err => {
            Notify.notifyError(err.response.data.reason);
        })
    }
    render() {

        return (
            <Container>
                <p>
                    <h2>Coupon Code</h2>
                </p>
                <Grid container spacing={1}>
                    <Grid item xs={3}> <TextField name={'coupon_code'} value={this.state.coupon_code}
                                                  onChange={(event) => this.setState({[event.target.name]: event.target.value})}
                                                  style={{width: '100%'}} id="outlined-basic" label="Code"
                                                  variant="outlined"/> </Grid>
                    <Grid item xs={2}> <Button onClick={this.check_coupon} variant={'contained'}>Apply</Button></Grid>
                </Grid>
                <Grid container>
                    <h2>You are ready to proceed to checkout!</h2>
                </Grid>

                <Grid container>
                    <h3>Select Payment Method</h3>
                </Grid>
                <Grid container>
                    <CustomizedDialogs  title='Add New Method'><CreatePaymentMethod on_add={this.add_method}/> </CustomizedDialogs>
                </Grid>
                <Grid container>
                    <div class="form-group">
                        <FormControl component="fieldset">
                            <FormLabel component="legend"></FormLabel>
                            <RadioGroup aria-label="Payment Method" name="payment_method_id"
                                        value={this.state.payment_id} onChange={this.handleChange}>
                                {this.state.methods.map(method => {
                                    return (
                                        <p>
                                            <FormControlLabel value={method.payment_id} control={<Radio/>}
                                                              label={"Last 4 digits " + method.last4}/>
                                        </p>
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>


                    </div>
                </Grid>
                <TotalPrice form_data={this.props.form_data}/>
            </Container>
        );
    }
}
export default ReadyToCheckout


export const stripe_public_key = 'pk_live_ANAuXsQv0SUgM8B6ffhDkxer00ahfj4Zbl';
// export const base_site = 'http://127.0.0.1:8000';
export const base_site = 'https://personalshopper.personalisedstories.co.uk';

export const site_urls = {
    home: {verboseName: 'Home', url: '/',},
    start_process: {verboseName: 'Choose Your Plan', url: '/choose-your-plan',},
    subscription_list: {verboseName: 'List Subscription', url: '/subscriptions',},
    add_payment: {verboseName: 'Add Payment', url: '/add-payment',},
    start_subscription: {verboseName: 'Start Subscription', url: '/start-subscription',},
    chose_book: {verboseName: 'Choose Book', url: '/choose-book',},
    detail_subscription: {verboseName: 'Subscription Detail', url: '/subscription/:id', private: true},
};

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
//export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
//    const map = new Map<K, Array<V>>();
export function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export function total_price(form_data) {
    let additional_price = 0;
    let message = false;
    // console.log(Object.entries(this.state.book_props));
    const postage_price = form_data.postage_per_book * form_data.books_included;
    Object.entries(form_data.book_props).map(value => {
        const [book_id, props] = value;
        if (props.message) {
            message = true;
            additional_price += 2;
        }
        if (props.giftbox) {
            additional_price += 5;
        }
    })
    if (message) {
        additional_price -= 2;
    }

    const total = parseFloat(form_data.price) + additional_price + postage_price;
    let discounted_price = total;
    if (form_data.coupon_code) {
        const [type, val] = form_data.coupon_data;
        if (type === "percentage")
            discounted_price = total - total * val / 100;
    }
    return {additional_price, total, discounted_price, postage_price};
}

export function removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

import React, {Component} from "react";
import Plans from "./demo_data/plans";
import {AppBar, Box, Grid, RadioGroup, Tab, Tabs} from "@material-ui/core";
import PlansInfo from "./PlanInfo";
import * as PropTypes from "prop-types";
import {PricingCard,} from "./pricing";
import Pricing from "./pricing";
import {groupBy} from "./constants";
import Container from "@material-ui/core/Container";
import http from "../api-service/http-common";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default class StepOne extends Component{
    constructor(props) {
        super(props);
        const {plan} = this.props.form_data;
        this.state = {
            active_tab: 0,
            plans: [],
            plan,

        };
    }
    componentDidMount() {
        this.form_validator();
        this.get_plans();

    }
    get_plans = () => {
        http.get('https://personalshopper.personalisedstories.co.uk/subscriptions/api/plans/').then(res => {
            // console.log(res.data.results);
            this.setState({plans: res.data.results});
        }).catch(err => {
            // console.log(err)
        })
    }
    toggle_tab = (tab,value) => {
        console.log(tab);
        console.log(value);
        if(this.state.active_tab!==tab){
            this.setState({
                active_tab: value
            });
            this.props.handle_change({target: {
                    name: 'book_type',
                    value: tab,
                }});

        }
    };
    form_validator = event => {
        // this.props.handle_change(event);
        
        if(this.props.form_data.plan){
            this.props.handle_state_change({
                next_button_disabled: false,
            })
        }
        else{
            this.props.handle_state_change({
                next_button_disabled: true,
            })
        }
    };
    plan_updated = event => {
        const {name, value} = event.target;
        let obj = {};
        obj[name] = (value);
        const [plan, monthly] = value.split('-');
        this.props.form_data.plan = plan;
        const p = this.state.plans.find(p=>p.id==plan);
        this.props.form_data.price = p.total_price;
        this.props.form_data.plan_name = p.name;
        if(monthly){
            this.props.form_data.monthly = true;
            this.props.form_data.price = p.amount;
        }
        else{
            this.props.form_data.monthly = false;
        }
        this.props.form_data.books_included = p.books_included;
        this.props.form_data.books = [];
        this.setState(obj);
        this.form_validator(event);
    }
    render() {
        const grouped = groupBy(this.state.plans.filter(plan=>plan.payment_type), plan=> plan.name);
        return (
        <React.Fragment>
            <Container maxWidth="lg" component="main">
                <RadioGroup name={'plan'} value={this.state.plan} onChange={(event) => this.plan_updated(event)}>
                <Grid container spacing={5} alignItems="flex-end">
                    {

                        [...grouped].map(name=>{
                            const [type,items] = name;

                            return <PricingCard tier={items}/>;

                        })
                    }
                </Grid>
                </RadioGroup>
            </Container>

        </React.Fragment>
        );
    }
}

import Swal from 'sweetalert2';
class Notify {
    /**
     * @method notifyError
     * @memberof Notify
     * @static
     * @param {string} error
     *
     * @returns {void}
     */
    static notifyError(error) {
        Swal.fire('', error, 'error');
    }

    /**
     * @method notifySuccess
     * @member Notify
     * @static
     * @param {string} message
     *
     * @param {string} endpoint
     * @param reload
     * @param icon
     * @returns {void}
     */
    static async notifySuccess(message, endpoint, reload=true, icon='success') {
        await Swal.fire('', message, icon);
        if(reload){

        if(endpoint)
            window.location = endpoint;
        else
        window.location.reload();
        }
    }
    static notifyInfo(message, icon='info') {
        Swal.fire('', message, icon);

    }
    static NotifyLoading(){
        Swal.showLoading()
    }
}

export default Notify;

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import ChooseYourPlan from "./process";
import ChooseYourBook from "./choose-books";
// import ProcessPayment from "./process-payment";
import InjectedCheckoutForm from "./create-payment-method";
import ListSubscriptions from "./list-subscriptions";
import {site_urls} from './constants';
import SubscriptionDetail from './subscription-detail';
import VerticalLinearStepper from "../test";

const Router = () => (
    <main>
        <Switch>
            <Route exact path={site_urls.home.url}          component={Home}/>
            <Route path={site_urls.start_process.url}       component={ChooseYourPlan}/>
            <Route path={site_urls.chose_book.url}          component={ChooseYourBook} />
            <Route path={site_urls.start_subscription.url}  component={InjectedCheckoutForm} />
            <Route path={site_urls.add_payment.url}         component={InjectedCheckoutForm} />
            <Route path={site_urls.subscription_list.url}   component={ListSubscriptions} />
            <Route path={site_urls.detail_subscription.url}   component={SubscriptionDetail} />
            <Route path={'/test'}   component={VerticalLinearStepper} />
        </Switch>
    </main>
);
// import Roster from './Roster'
// import Schedule from './Schedule'

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
export default Router

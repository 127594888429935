// eslint-disable-next-line
import logo from './logo.svg';
import './App.css';
import store from "./middlewares/store";
import Header from './components/Header';
import React, {Component, Fragment} from "react";
import { Link, BrowserRouter } from "react-router-dom";
import Routers from "./components/Routers";
import CustomerDetail from './api-service/customer-details';
import * as auth from './middlewares/authActions';
// import cookie from 'react-cookies';
// import Cookies from 'universal-cookie';
import {Provider} from "react-redux";
import {ThemeProvider} from "styled-components";
import theme from './theme';
const user = localStorage.getItem('user');
if (user){
    try{
        store.dispatch(auth.setCurrentUser(JSON.parse(user)));
    }catch (e)
    {
        console.log(e)
    }
}

class App extends Component {

    componentDidMount() {

        CustomerDetail.is_logged_in().then(res=> {
                console.log(res.data)
                store.dispatch(auth.setCurrentUser(res.data));
                localStorage.setItem('user', JSON.stringify(res.data))
            }
        ).catch(err => {
            store.dispatch(auth.setUserError(err.data))
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                // dev code
                store.dispatch(auth.setCurrentUser(err.data));

            } else {
                // production code
            }
            localStorage.removeItem('user',)

            console.log(err)
        })

    }

    render() {

      // document.body.style.setProperty('backgroundColor', '#3788d8', 'important');
      return (
        <BrowserRouter>
            <Provider store={store}>
        <ThemeProvider theme={theme}>
            <Header />
            <Routers />
        </ThemeProvider>
            </Provider>
        </BrowserRouter>
    );
  }
}
export default App;

import React, {Component} from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    Table,
    TableBody, TableCell,
    TableContainer, TableRow,
    FormControlLabel, Checkbox, TableHead
} from "@material-ui/core";
import {base_site, site_urls} from "./constants";
import http from "../api-service/http-common";
import {withStyles} from "@material-ui/core/styles";

const url = base_site + '/subscriptions/api/subscriptions/';


class SubscriptionDetail extends Component {
    constructor(props) {
        super(undefined);
        this.pk = parseInt(props.match.params.id, 10);

        this.formdata = {}
        this.state = {
            subscription: null
        }
    }

    componentDidMount() {
        this.pk = parseInt(this.props.match.params.id, 10);
        http.get(url + this.pk.toString() + '/').then(res => {
            this.setState({subscription: res.data})
        });
    }

    render() {
        const {subscription} = this.state;
        const {classes} = this.props;
        return (
            <Container style={{marginTop: '1em'}}>
                {subscription ?
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell class={classes.headerCell}>Plan</TableCell>
                                    <TableCell>{subscription.plan_detail.name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell class={classes.headerCell}>Subcription Date</TableCell>
                                    <TableCell>{subscription.plan_detail.name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell class={classes.headerCell}>Subscribed at</TableCell>
                                    <TableCell>{subscription.created_at}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell class={classes.headerCell}>Amount</TableCell>
                                    <TableCell>{subscription.price}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell class={classes.headerCell}>Books Included</TableCell>
                                    <TableCell>{subscription.plan_detail.books_included}</TableCell>
                                </TableRow>
                                <TableRow>
                                </TableRow>
                            </TableBody>


                        </Table>
                        <TableContainer className={classes.table}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell class={classes.headerCell}>ID</TableCell>
                                        <TableCell class={classes.headerCell}>Book Name</TableCell>
                                        <TableCell class={classes.headerCell}>Status</TableCell>
                                        <TableCell class={classes.headerCell}>Process Date</TableCell>
                                        <TableCell class={classes.headerCell}>Approximate Delivery Date</TableCell>
                                        <TableCell class={classes.headerCell}>Book Extra's</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {subscription.books.map(book => {

                                            return <TableRow>
                                                <TableCell>{book.book}</TableCell>
                                                <TableCell>{book.book_name}</TableCell>
                                                <TableCell>{book.status}</TableCell>
                                                <TableCell>{book.process_date}</TableCell>
                                                <TableCell>{book.delivery_date}</TableCell>
                                                <TableCell>{
                                                    book.props ?
                                                        <Grid container>
                                                            <FormControlLabel disabled control={<Checkbox
                                                                checked={book.props.giftbox} name="checkedE"/>}
                                                                              label="Giftbox"/>
                                                            <FormControlLabel disabled control={<Checkbox
                                                                checked={book.props.message} name="checkedE"/>}
                                                                              label="Message"/>
                                                            {book.props.message ?
                                                                <Button
                                                                    component={'a'}
                                                                    href={book.props.image}
                                                                    target='_blank'
                                                                    size='small' variant={'contained'} color={'secondary'}
                                                                >
                                                                    Open Image
                                                                </Button>
                                                                : ''
                                                            }
                                                        </Grid>
                                                        : ''
                                                }</TableCell>
                                            </TableRow>
                                        }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </TableContainer>
                    : ''
                }


            </Container>
        );
    }
}

const styles = (theme => ({
    root: {
        minWidth: 275,
        marginTop: theme.spacing(5),
    },

    title: {
        fontSize: 14,
    },
    headerCell: {
        fontWeight: 700,
    },
    table: {
        marginTop: theme.spacing(4),
    }
}));

export default withStyles(styles, {withTheme: true})(SubscriptionDetail);


import axios from "axios";
let http = axios.create({
    // baseURL: "https://coffeeshopbackend.gukly.com",
    baseURL: "https://personalshopper.personalisedstories.co.uk",
    withCredentials: true
});

// http.interceptors.request.use( (config) =>{
//     // console.log(config);
//     if (localStorage.authToken) {
//         config.headers.common['Authorization'] = 'Token ' + localStorage.authToken;
//     }
//     return config
// })
export default http

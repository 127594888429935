import React, {Component} from "react";
import {Link} from "react-router-dom";
import {site_urls} from "./constants";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from 'react-redux';
import poppins from '../assets/fonts/Poppins/Poppins-Black.ttf';

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: '#f9e8a4'
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
        fontFamily: theme.headlines.headline.fontFamily,
        color: theme.headlines.headline.color,
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
}));


function Header(props){
    const classes = useStyles();

    return (
            <>
                <CssBaseline />
                <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                            <figure className="wpb_wrapper vc_figure">
                                <a href="https://personalisedstories.co.uk/" target="_self"
                                   className="vc_single_image-wrapper   vc_box_border_grey"><img data-lazyloaded="1"
                                                                                                 src="https://personalisedstories.co.uk/wp-content/uploads/2021/08/personalised-logo-left-200x70px.png.webp"
                                                                                                 data-src="https://personalisedstories.co.uk/wp-content/uploads/2021/08/personalised-logo-left-200x70px.png.webp"
                                                                                                 className="vc_single_image-img attachment-full litespeed-loaded"
                                                                                                 alt="logo"
                                                                                                 loading="lazy"
                                                                                                 data-srcset="https://personalisedstories.co.uk/wp-content/uploads/2020/06/logo-200x70px-2.png 200w, https://personalisedstories.co.uk/wp-content/uploads/2020/06/logo-200x70px-2-150x53.png 150w"
                                                                                                 data-sizes="(max-width: 200px) 100vw, 200px"
                                                                                                 sizes="(max-width: 200px) 100vw, 200px"
                                                                                                 data-was-processed="true"
                                                                                                 width="200"
                                                                                                 height="70"/>
                                    <noscript><img width="200" height="70"
                                                   src="https://personalisedstories.co.uk/wp-content/uploads/2020/06/logo-200x70px-2.png"
                                                   className="vc_single_image-img attachment-full" alt="logo"
                                                   loading="lazy"
                                                   srcSet="https://personalisedstories.co.uk/wp-content/uploads/2020/06/logo-200x70px-2.png 200w, https://personalisedstories.co.uk/wp-content/uploads/2020/06/logo-200x70px-2-150x53.png 150w"
                                                   sizes="(max-width: 200px) 100vw, 200px"/></noscript></a></figure>
                        </Typography>
                        <nav>

                            {props.auth.isAuthenticated ?
                                <a className={classes.link} href={'https://personalisedstories.co.uk/logout'}>Logout</a>:
                                <a className={classes.link} href={'https://personalisedstories.co.uk/login?redirect_to=https://subscriptions.personalisedstories.co.uk'}>Login</a>
                            }

                        </nav>
                    </Toolbar>
                </AppBar>
                <CssBaseline />

            </>

        );
    }
const mapStateToProp = state => ({
    auth: state.auth
})

export default connect(mapStateToProp) (Header);

